import featureFlags from './feature-flags.json'

export class FeatureFlagManager {

    constructor() {
        localStorage.setItem('flags', JSON.stringify(featureFlags))
    }

    getFeatureFlag(name) {
        const features = JSON.parse(localStorage.getItem('flags'));
        
        return features.find(feature => feature.name === name);
    }

    isEnabled(name) {
        const featureFlag = this.getFeatureFlag(name);
        return featureFlag ? featureFlag.active : false;
    }
}