import { FeatureFlagManager } from "./FeatureFlagManager";

const Feature = ({name, children}) => {

    const featureFlagManager = new FeatureFlagManager();

    if (featureFlagManager.isEnabled(name)) {
        return children;
    }

    return null;
};

export default Feature;