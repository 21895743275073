import './Contact.css';

const Contact = () => {
    return (
        <div class="contact">
            <div>
            I am always eager to hear about your <b>business problems</b> and discuss possible <b>solutions</b> with you. Whether you're looking for help with software design, cloud infrastructure, or any other technology-related issue, <b>I'm here to help</b>.
            </div>
            <ul>
                <li>
                    <a itemprop="email" href="mailto:talk@juanramos.space">talk [at] juanramos.space.</a>
                </li>
            </ul>
        </div>
    );
}

export default Contact;