import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LastLinks from './components/last-links/LastLinks';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Contact from './components/contact/Contact';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Contact />
  },
  {
    path: "/my-work",
    element: <LastLinks />
  },
  {
    path: "/contact",
    element: <Contact />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App>
      <RouterProvider router={router} />
    </App>
  </React.StrictMode>
);
