import { marked } from 'marked';

import './LastLinks.css';
import LastLinksData from './last-links.json';

const LastLinks = () => {

    return (LastLinksData 
            && LastLinksData.length > 0
            && <div className='last-links'>
                <h2>My latest work:</h2>
                <ul>
                    {
                        LastLinksData.map((link, i) => {
                            return (
                                <li key={i}>
                                    <div dangerouslySetInnerHTML={{ __html: marked(link.content) }} />
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
    );
}

export default LastLinks;