import Menu from './components/menu/Menu';
import SocialLinks from './components/social-links/SocialLinks';
import Feature from './feature-flags/Feature.js';

function App(props) {
  return (
    <>
      <div class="container p-4 margin-t-10-p">
          <div class="row d-flex justify-content-center">
            <div class="col-lg-6">
              <div class="">
                <h1>Juan Ramos</h1>
                <p className='subtitle margin-t-30'><b>About Cloud & Software Architecture.</b></p>
                
                <Feature name="menu">
                  <Menu />
                </Feature>
        
                <div className="space" />

                {props.children}
              </div>
            </div>
          </div>
      </div>

      <Feature name="social-links">
        <SocialLinks />
      </Feature>
    </>
  );
}

export default App;
