import Feature from "../../feature-flags/Feature";
import "./Menu.css";

const Menu = () => {
    return (
        <div className="menu margin-t-30">
            <ul>
                <Feature name="menu-item-blog">
                    <li>
                        <a href="https://medium.com/@ptjuanramos" target="_blank" rel="noreferrer">Blog</a>
                    </li>
                </Feature>

                <Feature name="menu-item-home">
                    <li>
                        <a href="/">Home</a>
                    </li>
                </Feature>

                <Feature name="menu-item-my-work">
                    <li>
                        <a href="/my-work">My Work</a>
                    </li>
                </Feature>

                <Feature name="menu-item-contact">
                    <li>
                        <a href="/contact">Contact</a>
                    </li>
                </Feature>
            </ul>
        </div>
    );
}

export default Menu;